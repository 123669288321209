<template>
    <div class="collectionDetails">
      <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/collect' }">集采服务</el-breadcrumb-item>
          <el-breadcrumb-item>详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="details">
        <div class="title">
          <h3>{{ details.name }}</h3>
          <div>
            <ul class="flex">
              <li>
                <i class="el-icon-time"></i>
                <span>{{ details.create_time || '未知' }}</span>
              </li>
              <li>
                <i class="el-icon-view"></i>
                <span>{{ details.view || '未知' }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!--文章内容-->
        <div class="content" v-html="details.content">

        </div>
      </div>
    </div>
</template>

<script>
import { articleDetails } from '@/api/collect/collect'
export default {
  name: 'collectionDetails',
  data() {
    return {
      details: '' // 文章详情
    }
  },
  created() {
    this.getarticleDetails()
  },
  methods: {
    getarticleDetails() { // 获取文章详情
      articleDetails({ id: this.$route.query.id }).then(res => {
        this.details = res.data;
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .collectionDetails {
    width: 1200px;
    margin: 0 auto;
    .breadcrumb {
      margin: 22px 0;
    }
    .details {
      background-color: #fff;
      min-height: 680px;
      margin-bottom: 20px;
      padding: 0 40px;
      .title {
        padding-top: 40px;
        padding-bottom: 26px;
        border-bottom: 1px solid #eee;
        ul li {
          color: #666;
          margin-right: 30px;
          i {
            font-size: 16px;
          }
          span {
            font-size: 16px;
          }
        }
      }
    }
  }
</style>
