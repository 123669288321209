import { render, staticRenderFns } from "./collectionDetails.vue?vue&type=template&id=576f3ee6&scoped=true&"
import script from "./collectionDetails.vue?vue&type=script&lang=js&"
export * from "./collectionDetails.vue?vue&type=script&lang=js&"
import style0 from "./collectionDetails.vue?vue&type=style&index=0&id=576f3ee6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "576f3ee6",
  null
  
)

export default component.exports